<template>
  <div>
    <h3 class="pb-2">
      Spot Type Management
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.spotTypeForm"
    />
    <div v-if="!loading">
      <div v-if="!spotType">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">Spot Types</div>
          </div>
          <div class="card-body">
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="spotTypes"
              stacked="md"
              small
              class="mt-4"
              bordered
              sort-icon-left
            >
              <template v-slot:cell(id)="data">
                <button
                  class="btn btn-primary btn-sm mr-2"
                  @click="viewSpotType(data.item.id)"
                >
                  View
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="modifySpotType(data.item.id)"
                >
                  Modify
                </button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header">
          <div class="card-title mb-0">Spot Type</div>
        </div>
        <div class="card-body">
          <ValidationObserver ref="spotTypeForm">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Spot Type Name"
                    id="name"
                    rules="required"
                    v-model="spotType.name"
                    :disabled="viewOnly"
                  />
                  <small class="form-text text-muted mb-2"
                    >The name for the spot type.</small
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="required"
                    name="Spot Type Icon"
                    id="icon"
                    v-model="spotType.icon"
                    :disabled="viewOnly"
                  />
                  <small class="form-text text-muted mb-2"
                    >The icon for the spot type.</small
                  >
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-primary mt-4 mr-2"
                v-show="!viewOnly"
              >
                Save
              </button>
              <button
                class="btn btn-lg btn-primary mt-4 mr-2"
                @click="viewOnly = false"
                type="button"
                v-show="viewOnly"
              >
                Modify
              </button>
              <button
                class="btn btn-cancel mt-4 mr-2"
                @click="cancel"
                type="button"
              >
                Cancel
              </button>
              <p class="mt-2">
                <span style="color: red;">*</span> Indicates a required field
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "AdminSpotTypeManagement",
  title: "Admin - Spot Type Management",
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert
  },
  data() {
    return {
      loading: false,
      errors: [],
      spotTypes: [],
      fields: [
        { key: "name", sortable: true, label: "Spot Type Name" },
        { key: "id", label: "Actions/Options", class: "actionsColumn" }
      ],
      spotType: null,
      viewOnly: false
    };
  },
  methods: {
    async getSpotTypes() {
      this.loading = true;
      const service = new AdminLookupService(this.tenantId);
      const response = await service.getAllSpotTypes();
      if (response?.statusCode === "Success") {
        this.spotTypes = response.spotTypes;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    async onSubmit() {
      this.$refs.spotTypeForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.spotTypeForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.spotTypeForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            const service = new AdminLookupService(this.tenantId);
            const response = await service.updateSpotType(this.spotType);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message:
                  "Spot Type " + this.spotType.name + " successfully modified.",
                layer: "admin"
              });
              this.getSpotTypes();
              this.cancel();
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    viewSpotType(id) {
      this.viewOnly = true;
      this.spotType = { ...this.spotTypes.find(x => x.id === id) };
    },
    modifySpotType(id) {
      this.viewOnly = false;
      this.spotType = { ...this.spotTypes.find(x => x.id === id) };
    },
    cancel() {
      this.errors = [];
      this.viewOnly = false;
      this.spotType = null;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getSpotTypes();
  }
};
</script>

<style>
.actionsColumn {
  width: 300px !important;
}
</style>
